import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request'

import $ from 'jquery'
Vue.prototype.$ = $

import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/font/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/element/index.css';
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
import * as custom from '@/utils/date'
// 全局注册
Object.keys(custom).forEach(key => { Vue.filter(key, custom[key])})

//全局导入，在main.js中导入（可选）
import Clipboard from 'clipboard'
Vue.prototype.Clipboard=Clipboard;
//局部导入，在对应文件导入
// import clipboard from 'clipboard'

import '@/assets/style/base.css'
import '@/assets/style/common.css'
// import '@/assets/style/indexSwiper.css'
import pdf from 'vue-pdf'

Vue.prototype.$http = axios
Vue.use(Vant)
Vue.use(ElementUI);

// Vue.config.productionTip = false

import animate from 'animate.css'
 
Vue.prototype.animate = animate


Vue.prototype.$bus = new Vue();

//main.js
//导入
import {
  message
} from '@/utils/resetMessage';


// console.log = function() {}

Vue.use(ElementUI)
//挂载
Vue.prototype.$message = message;


import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx



new Vue({
  router,
  pdf,
  store,
  // $,
  render: h => h(App)
}).$mount('#app')
