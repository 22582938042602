import request from '@/utils/request'
/**
 * 获取用户个人信息
 */
export function personalMessage(query) {
  return request({
    url: 'user/catinfo/',
    method: 'get',
    params: query
  })
}
/**
 * 获取用户今年与今日各类型文限量
 */
 export function wenNumber(query) {
    return request({
      url: 'user/sortcount/',
      method: 'get',
      params: query
    })
  }
  /**
 * 查看个人用户消息已读、未读的内容
 */
 export function information(query) {
    return request({
      url: 'user/hadreads/',
      method: 'get',
      params: query
    })
  }
  /**
 * 获取近3天的系统消息
 */
 export function threeDay(query) {
  return request({
    url: 'user/systeminfo/',
    method: 'get',
    params: query
  })
}

 /**
 * 获取用户系统消息
 */
  export function fetchMessage(query) {
    return request({
      url: 'user/system_msg/',
      method: 'get',
      params: query
    })
  }

/**
 * 系统消息已读
 */
 export function readMessage(data) {
  return request({
    url: 'user/system_msg/',
    method: 'post',
    data
  })
}

 /**
 * 注册获取手机号验证码
 */
 export function phoneRegister(query) {
  return request({
    url: 'user/phoneregister/',
    method: 'get',
    params: query
  })
}

 /**
 * 检索用户所在机构院系
 */
 export function serachDepartment(query) {
  return request({
    url: 'user/serach_department/',
    method: 'get',
    params: query
  })
}
/**
 * 完善用户信息
 */
export function userComplete(data) {
  return request({
    url: 'user/Complete_user_information/',
    method: 'post',
    data
  })
}

 /**
 * 修改邮箱地址-获取邮箱验证码
 */
 export function emailRegister(query) {
  return request({
    url: 'user/editemail/',
    method: 'get',
    params: query
  })
}

 /**
 * 生成小程序码
 */
 export function appletWx(query) {
  return request({
    url: 'user/Wx_applet_code/',
    method: 'get',
    params: query
  })
}